<template>
  <div class="ParentalAccess div-template" >

    <notification-popup  
    :dialog="notifSuccess"
    :contentMsg="$t('succesfully')"
    :headerMsg="$t('notifSuccessApprove')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "success"
    typeModal="transaction"
    Icon="mdi-check-circle"
    :onHandlerNo="cancelSuccess"
    :onHandlerYes="buttonOke"/>

    <notification-popup 
    :dialog="notifFailed"
    :contentMsg="$t('failed')"
    :headerMsg="$t('notifFailedApprove')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
    :onHandlerNo="cancelFailed"
    :onHandlerYes="buttonOkeFailed"/>
    
    <!-- end notif delete -->
    <notification-popup  
    :dialog="notifSuccessDelete"
    :contentMsg="$t('succesfully')"
    :headerMsg="$t('notifSuccessDelete')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "success"
    typeModal="transaction"
    Icon="mdi-check-circle"
    :onHandlerNo="cancelnotifSuccessDelete"
    :onHandlerYes="clickbuttonOkeDelete"/>

    <notification-popup 
    :dialog="notifFailedDelete"
    :contentMsg="$t('failed')"
    :headerMsg="$t('notifFailedApprove')"
    :nameBtnNo="$t('labelNo')"
    :nameBtnYes="$t('labelYes')"
    colorIcon = "danger"
    typeModal="transaction"
    Icon="mdi-close-circle"
    :onHandlerNo="cancelnotifFailedDelete"
    :onHandlerYes="buttonOkeFailedDelete"/>
    <!-- end notif delete -->
    
    <!-- <notification-popup 
    :dialog="dialogRemoveChild"
    contentMsg=" "
    :headerMsg="$t('dialogDelete')"
    nameBtnNo="No"
    nameBtnYes="Yes"
    colorIcon = "danger"
    :onHandlerYes="clickDeleteChild"/> -->

    <div align="center">
      <h4 class="h4-title">{{$t('ParentalAccess')}}</h4>
    </div>

    <template >
      <!-- data request student -->
      <div 
      v-if="dataReqStudent.length > 0">
        <v-row 
        align="center"
          v-for="(item, index)  in dataReqStudent"
          :key="index">
          <v-col
            cols="4"
          >
            <v-list-item-title @click="clickDetail(item.email)" class="h4-title onHover" v-text="item.name"></v-list-item-title>
          </v-col>

          <v-col
            cols="3"
            align="left"
          >
            <v-list-item-title class="h4-title" v-text="item.registration_date"></v-list-item-title>
          </v-col>

          <v-col
            cols="3"
            align="left"
          >

            <v-btn
            class="text-capitalize"
              small
              rounded
              color="primary"
              @click="clickShowPassword(item.email)"
            >
              {{$t('Approve')}}
            </v-btn>
          </v-col>

          <v-col
            cols="2"
            align="right"
          >
            <v-icon
              @click="clickShowPasswordDelete(item.email)"
              color="grey lighten-1">
              mdi-delete-outline
            </v-icon>
          </v-col>

        </v-row>
      </div>
      <!-- end data request student -->

      <!-- data student -->
      <div 
      v-if="dataStudent.length > 0">
        <v-row 
        align="center"
          v-for="(item, index)  in dataStudent"
          :key="index">
          <v-col
            xs="4"
            sm="4"
            md="4"
            lg="4"
          >
            <v-list-item-title @click="clickDetail(item.email)"  class="h4-title onHover" v-text="item.name"></v-list-item-title>
          </v-col>

          <v-col
            xs="4"
            sm="4"
            md="4"
            lg="4"
          >
            <v-list-item-title class="h4-title" v-text="item.registration_date"></v-list-item-title>
          </v-col>
          
          <v-col
            xs="4"
            sm="4"
            md="4"
            lg="4"
            align="right"
          >
            <v-icon
              @click="clickShowPasswordDelete(item.email)"
              color="grey lighten-1">
              mdi-delete-outline
            </v-icon>
          </v-col>

        </v-row>
      </div>
      <!-- end request student -->
      <div v-if="showTemplatePassword == true">
          <v-text-field
            v-model="password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            :label="$t('password')"
            hint="At least 6 characters"
            class="input-group--focused"
            @click:append="show = !show"
            outlined
            rounded
          ></v-text-field>
          <v-btn
            small
            rounded
            color="primary"
            @click="clickApprove()"
            >
            {{$t('done')}}
          </v-btn>
      </div>


      <!-- end request student -->
      <div v-if="showTemplatePasswordDelete == true">
          <v-text-field
            v-model="passwordDelete"
            :append-icon="showPasswordDelete ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPasswordDelete ? 'text' : 'password'"
            name="input-10-2"
            :label="$t('password')"
            :hint="$t('AtleastSixCharacters')"
            class="input-group--focused"
            @click:append="showPasswordDelete = !showPasswordDelete"
            outlined
            rounded
          ></v-text-field>
          <v-btn
            small
            rounded
            color="primary"
            @click="clickDeleteChild()"
            >
            {{$t('done')}}
          </v-btn>
      </div>
    </template>
  </div>
  
</template>

<script>
import moment from 'moment'
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'ParentalAccess',
    
    components: {NotificationPopup, },
    data() {
      return {
        disabledBtn:"",
        notifSuccess:false,
        notifFailed:false,
        notifSuccessDelete:false,
        notifFailedDelete:false,
        dialogRemoveChild:false,
        alignments: [
          'start',
          'center',
          'end',
        ],
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 6 || "Min 6 characters",
          emailMatch: () => `The email and password you entered don't match`,
        },
        email: "",
        dataReqStudent: [],
        dataStudent: [],
        password:"",
        passwordDelete:"",
        show:false,
        showPasswordDelete:false,
        showTemplatePassword:false,
        showTemplatePasswordDelete:false,
        dataUser:[],
        deleteEmail: "",
        countReqStudent:0,
        countStudent:0
      }
    },
    methods:{
      clickDetail(item){
        this.$router.push({
          name: 'DetailStudent',
          params: {
              items: item
          }
        })
      },
      // showDialogRemove(item){
      //   this.dialogRemoveChild =  true
      //   this.deleteEmail = item
      // },
      clickDeleteChild(){
        const payload = {
            student_email:this.deleteEmail,
            password:this.passwordDelete,
        };

        this.$store
          .dispatch("childlinkage/delete_child", payload)
          .then((response) => {
            this.notifSuccessDelete = true
          if (response.status) console.log("success");
          })
          .catch((err) => {
            this.notifFailedDelete = true
            console.log(err);
          });
      },
      clickbuttonOkeDelete(){
        this.dialogRemovePayment= false
        this.notifSuccessDelete = false
        if (this.notifSuccessDelete == false) this.$router.push({ name: "Home" }) ;
      },
      buttonOkeFailedDelete(){
        this.dialogRemovePayment= false
        this.notifFailedDelete = false
      },
      clickShowPassword(item){
        this.showTemplatePassword =  true
        this.email = item
      },
      clickShowPasswordDelete(item){
        this.showTemplatePasswordDelete =  true
        this.deleteEmail = item
      },
      clickApprove(){

        const payload = {
            student_email:this.email,
            password:this.password,
        };

        this.$store
          .dispatch("childlinkage/confirm_student", payload)
          .then((response) => {
            this.notifSuccess = true
          if (response.status) console.log("success");
          })
          .catch((err) => {
            this.notifFailed = true
            console.log(err);
          });
      },
      clickDialogRemovePayment(){
        this.dialogRemovePayment= true
      },
      buttonOke(){
        this.notifSuccess = false
        if (this.notifSuccess == false) this.$router.push({ name: "Home" }) ;
      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      cancelFailed(){
        this.notifFailed=false;
      },
      cancelSuccess(){
        this.notifSuccess=false;
      },
      cancelnotifSuccessDelete(){
        this.notifSuccessDelete = false;
      },
      cancelnotifFailedDelete(){
        this.notifFailedDelete = false;
      },
    },
    mounted(){
       
      this.$store
        .dispatch("childlinkage/getStudentRequest")
        .then((response) => {
          if (response.data.length > 0 ) {
            for (const key of response.data) {
              key.registration_date =  moment(key.registration_date).format("YYYY-MM-DD hh:mm:ss");
              this.dataReqStudent.push(key);
            }
          }else{
            this.dataReqStudent = []
          }
        })
        .catch((err) => {
          console.log(err);
        });


      this.$store
        .dispatch("childlinkage/getStudent")
        .then((response) => {
          if (response.data.length > 0) {
            for (const key of response.data) {
              key.registration_date =  moment(key.registration_date).format("YYYY-MM-DD hh:mm:ss");
              this.dataStudent.push(key);
            }
          }else{
            this.dataStudent = []
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
</script>

<style lang="scss" scoped>
.div-template {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.label-minus {
  padding-left: -20px;
}

.h4-title{
  padding-top: 10px;
  padding-left: 10px;
}
.onHover{
  cursor: pointer;
}
.end{
  margin-right: 0px;
}


.btn-bottom{
  bottom:   0;
  margin-bottom: 15px;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>